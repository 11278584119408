import AuthorizedPermission from "@/components/AuthorizedPermission";
import { RouteObject, useRoutes } from "react-router-dom";

// 获取所有pages文件夹中所有子文件中的routes.tsx
const routesContext = require.context('../pages', true, /routes\.tsx$/);
const routesComplex = routesContext.keys().map(key => routesContext(key).default);
const routesMap = routesComplex.flat();

const pagesRoutes = routesMap.map(({path, element, permission}) => ({
  path,
  element: !permission ? element : <AuthorizedPermission permissionKey={permission}>{element}</AuthorizedPermission>
}));

const routes:RouteObject[] = [
  ...pagesRoutes,
  {
    path: '*',
    element: <></>,
  },
]
const RouterView:React.FC = () => useRoutes(routes);

export default RouterView;
