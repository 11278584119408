import React, { createContext, Key, useEffect, useState } from "react";
import { Breadcrumb, useBreadcrumbRoutes } from "@maxtropy/components";
import { Col, Layout, Row, Tabs } from "antd";
import BorderWrapper from "@/components/BorderWrapper";
import FormContent from "@/components/FormContent";
import HeadTitle from "./components/HeadTitle/HeadTitle";
import Supply from "./components/Supply";
import Elect from "./components/Elect";
import styles from "./index.module.scss";
import LoadRanking from "./components/LoadRanking";
import { ConfigResponse, getDeviceIdConfig } from "@/api/electric";


const { TabPane } = Tabs;
export const Config = createContext({} as ConfigResponse | undefined);

const ElectricityView: React.FC = () => {
  const [dataConfig, setDataConfig] = useState<ConfigResponse>();
  useEffect(() => {
    getDeviceIdConfig().then(res => {
      setDataConfig(res)
    })
  }, []) 

  return (
    <div className="page">
      <BorderWrapper>
        <div className={styles.func_area}>
          <HeadTitle title="供电总览" />
          <Supply config={dataConfig} />
        </div>
        <Row gutter={10}>
          <Col lg={16} md={24} sm={24}>
            <div className={styles.func_area} >
              <Config.Provider value={dataConfig}>
                <HeadTitle title="电能概览" />
                <Elect />
              </Config.Provider>
            </div>
          </Col>
          <Col lg={8} md={24} sm={24} >
            <div className={styles.func_area} style={{ height: '400px' }}>
              <HeadTitle title="负荷排名" />
              <LoadRanking loadRankingConfig={dataConfig?.loadRanking} />
            </div>
          </Col>
        </Row>
      </BorderWrapper>
    </div>
  );
};

export default ElectricityView;
