import { CircuitProps, getRawRecentList, PropertyIdsType } from '@/api/electric';
import { EllipsisSpan, useUpdate } from '@maxtropy/components';
import { Badge, Col, Row, Table } from 'antd'
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { FC, useEffect, useRef, useState } from 'react'

interface FireContentProps {
  circuitPhysicalQuantityName: string;
  dataPropertyId: number;
  checked: number;
}

const columns: ColumnsType<FireContentProps> = [
  {
    title: "负荷排名",
    dataIndex: "rankIndex",
    width: "20%",
    align: 'center',
    ellipsis: { showTitle: true },
    // render: (v) => <EllipsisSpan value={v} />,
  },
  {
    title: '总线名称',
    dataIndex: 'circuitName',
    // width: 100,
    render: (v, record: any) => {
      return <EllipsisSpan value={v} />
      // return v ? (
      //   <Button type='link' onClick={() => window.open(`${window.DMESPATH}?circuitPhysicalQuantityName=${record.circuitPhysicalQuantityName}`, '_blank')}>
      //     <EllipsisSpan value={v} />
      //   </Button>
      // ) : '--'
    }
  },
  {
    title: '实时负荷(kW)',
    dataIndex: 'value1',
    render: (v) => <EllipsisSpan value={v} />,
  },
  {
    title: '当月最大需量(kWh)',
    dataIndex: 'value2',
    render: (v) => <EllipsisSpan value={v} />,
  }
]

// 负荷排名useQuery轮询key
export const loadRankingKeys = {
  loadRanking: ["loadRanking"],
};

interface Iprops {
  loadRankingConfig?: CircuitProps[];
}
type Timer = NodeJS.Timeout | undefined
const LoadRanking: FC<Iprops> = (props) => {
  const [dataSource, setDataSource] = useState<any[]>([]);
  const timer = useRef<Timer>();
  const [updateState, updateFn] = useUpdate();
  useEffect(() => {
    if (props.loadRankingConfig) {
      getRawRecentList({
        deviceIds: (props.loadRankingConfig ?? []).map(item => item.deviceId!),
        propertyIds: [PropertyIdsType.POWER, PropertyIdsType.NEADS]
      }).then((res) => {
        let newData = JSON.parse(JSON.stringify(props.loadRankingConfig))
        // 做排名徽标
        newData.map((item: any, index: number) => {

          item.id = Math.random();
          let d = res.filter(i => item.deviceId === i.deviceId)
          d.map(itema => {
            if (itema.propertyId === PropertyIdsType.POWER) {
              // 实时负荷
              if (itema.time > dayjs().valueOf() - 3 * 60 * 1000) {
                item.value1 = itema.value.toFixed(2);
              } else {
                item.value1 = '--'
              }
            } else if (itema.propertyId === PropertyIdsType.NEADS) {// 当月最大需量
              // 当月最大需量
              if (dayjs(itema.time).isAfter(dayjs().startOf('month').valueOf()) && dayjs(itema.time).isBefore(dayjs().endOf('month').valueOf())
              ) {
                item.value2 = itema.value.toFixed(2);
              } else {
                item.value2 = '--'
              }
            }
          })

        })
        let finalData = newData.sort((a: { value1: number; value2: number; }, b: { value1: number; value2: number; }) => {
          if (a.value1 === b.value1) {
            return b.value2 - a.value2
          } else {
            return b.value1 - a.value1
          }
        })
        finalData.map((item: { rankIndex: JSX.Element; }, index: number) => {
          if (index === 0) {
            item.rankIndex = <Badge style={{ backgroundColor: '#BA8D0F' }} count={index + 1} />
          } else if (index === 1) {
            item.rankIndex = <Badge style={{ backgroundColor: '#A09E9A' }} count={index + 1} />
          } else if (index === 2) {
            item.rankIndex = <Badge style={{ backgroundColor: '#77442B' }} count={index + 1} />
          } else {
            item.rankIndex = <Badge style={{ backgroundColor: '#4F4F4F' }} count={index + 1} />
          }
        })
        setDataSource(finalData);
        timer.current = setTimeout(() => {
          updateFn()
        }, (60000))
      }).catch(err => {
        clearTimeout(timer.current)
      })
    }
    return () => clearTimeout(timer.current);
  }, [props, updateState]);


  return (
    <>
      <Row>
        <Col span={24} style={{ marginTop: '10px' }}>
          <Table
            // title={() => <div>发电机组</div>}
            size={'small'}
            rowKey={"id"}
            bordered
            dataSource={dataSource}
            columns={columns}
            pagination={{
              pageSize: 5
            }}
          />
        </Col>
      </Row>
    </>
  )
}

export default LoadRanking