import React from "react";
import styles from "./index.module.scss";

export interface NotAuthorizedProps {
  text?: string;
}

const NotAuthorized: React.FC<NotAuthorizedProps> = props => {
  const {text = "抱歉您没有此页面的操作权限噢，请与公司负责IT的人士联系，确认您是否可以开通对应权限"} = props;
  return (
    <>
      <div className={styles.pageNotAuthorized}>
        <div className={styles.content}>
          <div className={styles.logo}/>
          <div className={styles.text}>{text}</div>
        </div>
      </div>
    </>
  )
}

export default NotAuthorized;
