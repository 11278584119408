import { Col, Row, Spin } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import Empty from "@/components/NoData";
import ReactEcharts from "echarts-for-react";
import styles from "../../index.module.scss";
import MyProgress, { ProgressCate } from "./MyProgress";
import { ChartsResponse, ChartValueProps, ConfigResponse, getSupplyView, PropertyIdsType, TimeType } from "@/api/electric";
import moment from "moment";
import { formateValues } from "../../utils";
interface Iprops {
  config?: ConfigResponse;
}
const Supply: React.FC<Iprops> = ({ config }) => {
  const [isLoading, setIsLoading] = useState(false);
  // 当日小时累计电量
  const [todayCumulaElec, setTodayCumulaElec] = useState<ChartsResponse[]>();
  // 当月每日累计电量
  const [monthcumulaElec, setMonthcumulaElec] = useState<ChartsResponse[]>();
  // 所有设备id
  const deviveIds = useMemo(() => {
    if (!config) return;
    let { powerGridDeviceId, pvStation = [], thermalPowerPlant = [] } = config;
    let tempData = [...pvStation, ...thermalPowerPlant];
    let ids = tempData.map(v => v.deviceId!);
    return [powerGridDeviceId!, ...ids];
  }, [config])

  useEffect(() => {
    // 今日小时电量
    if (!deviveIds || deviveIds.length === 0) return;
    getSupplyView({
      deviceIds: deviveIds,
      propertyIds: [PropertyIdsType.ELECT],
      resolution: TimeType.HOUR,
      startTime: moment().startOf('day').valueOf(),
      endTime: moment().endOf('day').valueOf()
    }).then(setTodayCumulaElec)

    // 当月每天累计
    getSupplyView({
      deviceIds: deviveIds,
      propertyIds: [PropertyIdsType.ELECT],
      resolution: TimeType.DAY,
      startTime: moment().startOf('month').valueOf(),
      endTime: moment().endOf('day').valueOf()
    }).then(setMonthcumulaElec)


  }, [deviveIds])
  const isDataEmpty = useMemo(() => {
    return !monthcumulaElec || monthcumulaElec?.length === 0
  }, [monthcumulaElec])
  // 计算今日电量
  const todayElec = useMemo(() => {
    let temp = todayCumulaElec?.map(item => item.values).flat();
    let total = temp?.reduce((prev, next) => prev + (next?.value ?? 0), 0);
    return +(total?.toFixed(2) ?? 0);
  }, [todayCumulaElec]);

  // 计算当月电量
  const monthElec = useMemo(() => {
    let temp = monthcumulaElec?.map(item => item.values).flat();
    let total = temp?.reduce((prev, next) => prev + (next?.value ?? 0), 0);
    return +(total?.toFixed(2) ?? 0);
  }, [monthcumulaElec]);

  // 计算柱状图
  const totalChartData = useMemo(() => {
    let temp = monthcumulaElec?.map(item => item.values!).flat();
    return temp;
  }, [monthcumulaElec])

  const getDeviceIdsDataTotal = useCallback((type: string) => {
    if (!config) return 0;
    let { powerGridDeviceId, pvStation = [], thermalPowerPlant = [] } = config;
    let ids: number[] = [];
    if (type === 'net') {
      ids = [powerGridDeviceId!];
    } else if (type === 'pv') {
      ids = pvStation.map(item => item.deviceId!);
    } else {
      ids = thermalPowerPlant.map(item => item.deviceId!);
    }
    if (ids && ids.length > 0) {
      let temp = monthcumulaElec?.filter(item => ids.includes(item.deviceId!));
      if (temp && temp.length > 0) {
        let values = temp?.map(item => item.values).flat();
        let total = values?.reduce((prev, next) => prev + (next?.value ?? 0), 0);
        return +(total?.toFixed(2) ?? 0)
      }
      return 0
    }
    return 0
  }, [monthcumulaElec, config])

  // 电网月供电
  const netElec = getDeviceIdsDataTotal('net');
  // 光伏月供电
  const pvElec = getDeviceIdsDataTotal('pv');
  // 热电厂月供电
  const powerElec = getDeviceIdsDataTotal('power');

  return (
    <div className={styles.pd_horizal}>
      <Row gutter={10}>
        <Col lg={4} md={6} sm={24}>
          <div className={styles.supply}>
            <div className={styles.top}>
              <span className={styles.today_value}>{formateValues(todayElec)}</span>
              <div className={styles.line_through}><span className={styles.line_head}></span></div>
              <span className={styles.elec}>今日电量</span>
            </div>
            <div className={styles.bottom}>
              <span className={styles.month_value}>{formateValues(monthElec)}</span>
              <div className={styles.line_through}><span className={styles.line_head}></span></div>
              <span className={styles.elec}>当月累计</span>
            </div>
          </div>
        </Col>
        {/* 柱状图区域 */}
        <Col lg={10} md={18} sm={24}>
          <Spin spinning={isLoading}>
            {isDataEmpty ? (
              <Empty />
            ) : (
              <ReactEcharts
                option={getBarOption(totalChartData)}
                style={{ height: "300px" }}
                notMerge
                lazyUpdate={false}
              />
            )}
          </Spin>
        </Col>
        <Col lg={10} md={24} sm={24}>
          <div className={styles.progress_box}>
            <Row gutter={10}>
              <Col span={8}>
                <MyProgress
                  type={ProgressCate.NET}
                  value={netElec}
                  total={monthElec}
                />
              </Col>
              <Col span={8}>
                <MyProgress
                  type={ProgressCate.FIRE}
                  value={powerElec}
                  total={monthElec}
                />
              </Col>
              <Col span={8}>
                <MyProgress
                  type={ProgressCate.PV}
                  value={pvElec}
                  total={monthElec}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>

  )
}
const getBarOption = (data?: ChartValueProps[]) => {
  // item.values.map((v) => [v.time, v.value])
  const tempTime = data?.map(item => item.time!) ?? [];
  const uniqueTime = [...new Set(tempTime)];
  const seriesData = uniqueTime.map(item => {
    let temp = data?.filter(v => v.time === item);
    let total = temp?.reduce((prev, next) => prev + (next?.value ?? 0), 0);
    return [item, total]
  })
  return {
    tooltip: {
      trigger: "axis",
      formatter: (params: any[]) => {
        // `<span >${curr.marker} ${curr.seriesName}：</span> ${curr.data[1]?.toFixed(1) || "--"}kW<br />${params[0].axisValueLabel}<br />`
        return params.reduce(
          (acc, curr) =>
            acc + `<span>${curr.marker}</span> ${curr.data[1]?.toFixed(2) || "--"}kWh<br />`,
          `${moment(params[0].axisValueLabel).format('YYYY-MM-DD')}<br />`
        );
      },
    },
    dataZoom: [
      // {
      //   type: 'slider',
      //   start: 0,
      //   end: 40,
      //   bottom: 15,
      //   height: 20,
      //   backgroundColor: 'rgba(226,227,229,0.5)',
      //   fillerColor: 'rgba(142,177,224,0.3)',
      //   textStyle: {
      //     fontSize: 10
      //   }
      // },
      { type: 'inside' }
    ],
    xAxis: {
      min: moment().startOf('month').valueOf(),
      max: moment().endOf('day').valueOf(),
      axisTick: {
        show: false,
      },
      type: 'time',
      axisLabel: {
        show: true,
        color: "#ccc",
        fontSize: 10,
        // rotate: 30,
        formatter: (val: number) =>
          moment(val).format('DD'),
      },
    },
    yAxis: {
      name: 'kWh',
      type: 'value',
      // axisLabel: {
      //   formatter: ''
      // },
      axisTick: {
        show: false
      }
      // show: false
    },
    series: [
      {
        data: seriesData,
        type: 'bar',
        itemStyle: {
          color: '#16CEB9',
        }
      }
    ]
  }
}

export default Supply