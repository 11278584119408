import { ChartsResponse, CircuitProps, getRawRecentList, getSupplyView, PropertyIdsType, RecentResponseProps, TimeType } from "@/api/electric";
import { EllipsisSpan, useUpdate } from "@maxtropy/components";
import { Col, Row, Table } from "antd"
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { useContext, useEffect, useRef, useState } from "react";
import { Config } from "../..";
import styles from './index.module.scss'

import { Swiper, SwiperSlide } from 'swiper/react';
// import SwiperCore, { Pagination } from "swiper";
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

import './index.module.scss'
import { formateCurrentMonKWValues } from "../../utils";

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Autoplay]);



const columns: ColumnsType<CircuitResProps> = [
  {
    title: "发动机组",
    dataIndex: "circuitName",
    width: "30%",
    ellipsis: { showTitle: true },
    render: (v) => <EllipsisSpan value={v} />,
  },
  {
    title: '本月发电量(kWh)',
    dataIndex: 'currentMonthValue',
    render: (v) => <EllipsisSpan value={v} />,
  },
  {
    title: '当日发电量(kWh)',
    dataIndex: 'currentDayValue',
    render: (v) => <EllipsisSpan value={v} />,
  }
]

type Timer = string | number | NodeJS.Timeout | undefined

interface CircuitResProps extends CircuitProps {
  currentMonthValue?: string; // 因为转toFixed
  currentDayValue?: string;
  key?: number;
  circuitName?: string;
}
interface CircuitRawRecentProps extends CircuitProps {
  value?: string | number;
  key?: number;
}

const TabContent: React.FC = () => {
  const [tabsData, setTabsData] = useState<any[]>([]);
  const [dataSource, setDataSource] = useState<CircuitResProps[]>([]);
  const [totalNumber, setTotalNumber] = useState<number>(0)
  const timer1 = useRef<Timer>();
  const timer2 = useRef<Timer>();
  const configValue = useContext(Config);
  const [updateState1, updateFn1] = useUpdate();
  const [updateState2, updateFn2] = useUpdate();

  useEffect(() => {
    if (JSON.stringify(configValue) !== '{}' && configValue) {
      getRawRecentList({
        deviceIds: (configValue.thermalPowerPlant ?? []).map(item => item.deviceId!),
        propertyIds: [PropertyIdsType.POWER]
      }).then((res: RecentResponseProps[]) => {
        let newData: CircuitRawRecentProps[] = JSON.parse(JSON.stringify(configValue.thermalPowerPlant));
        // 最近原始数据
        newData.map((item: CircuitRawRecentProps) => {
          item.circuitName = item.circuitName ?? '--'
          let findDeviceId = res.find((i) => i.deviceId === item.deviceId)
          if (findDeviceId) {
            if (findDeviceId.value) {
              item.value = findDeviceId.value.toFixed(2)
            } else {
              item.value = (0).toFixed(2)
            }
          } else {
            item.value = (0).toFixed(2)
          }
          item.key = Math.random();
        })
        setTabsData(newData);
        let total: number = 0;
        newData.forEach((item) => {
          total += Number(item.value)
        })
        setTotalNumber(total);
        if (!timer1.current) {
          timer1.current = setTimeout(() => {
            updateFn1();
          }, (60000))
        }
      }).catch(err => {
        clearTimeout(timer1.current);
      })
    }

    return () => clearTimeout(timer1.current);
  }, [configValue, updateState1]);



  useEffect(() => {
    if (JSON.stringify(configValue) !== '{}' && configValue) {
      Promise.all([getSupplyView({
        deviceIds: (configValue.thermalPowerPlant ?? []).map(item => item.deviceId!),
        propertyIds: [PropertyIdsType.ELECT],
        resolution: TimeType.DAY,
        startTime: dayjs().startOf('day').valueOf(),
        endTime: dayjs().endOf('day').valueOf()
      }), getSupplyView({
        deviceIds: (configValue.thermalPowerPlant ?? []).map(item => item.deviceId!),
        propertyIds: [PropertyIdsType.ELECT],
        resolution: TimeType.MONTH,
        startTime: dayjs().startOf('month').valueOf(),
        endTime: dayjs().endOf('month').valueOf()
      })]).then(([info2, info1]) => {
        let newData: CircuitProps[] = JSON.parse(JSON.stringify(configValue.thermalPowerPlant));

        newData.map((item: CircuitResProps) => {
          item.circuitName = item.circuitName ?? '--'
          let findCurrentMonthValue = (info1 as ChartsResponse[]).find((i) => i.deviceId === item.deviceId)
          if (findCurrentMonthValue) {
            item.currentMonthValue = findCurrentMonthValue.values ? findCurrentMonthValue.values[0].value!.toFixed(2) : '--'
          }
          let findCurrentDayValue = (info2 as ChartsResponse[]).find((i) => i.deviceId === item.deviceId)
          if (findCurrentDayValue) {
            item.currentDayValue = findCurrentDayValue.values ? findCurrentDayValue.values[0].value!.toFixed(2) : '--'
          }
          item.key = Math.random();
        })

        setDataSource(newData)
        if (!timer2.current) {
          timer2.current = setTimeout(() => {
            updateFn2();
          }, (900000))
        }
      }).catch(err => {
        clearInterval(timer2.current);
      })
    }
    return () => clearInterval(timer2.current);
  }, [configValue, updateState2]);

  return <>
    <Row>
      <Col span={6} style={{ padding: '12px 0' }}>
        <h3 style={{ fontWeight: 700 }}>实时负荷</h3>
        <h4 style={{ fontWeight: 700 }}>{formateCurrentMonKWValues(Number(totalNumber.toFixed(2)), 'kW')}</h4>
      </Col>
      <Col span={18} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {/* <Row style={{ display: 'flex' }}>
          {tabsData?.map((item) => (
            <Col span={8}>
              <div style={{ textAlign: "center", fontSize: '16px' }}>{item.name ?? '--'}</div>
              <div style={{ textAlign: "center",fontSize: '14px', marginTop: '2px', fontWeight: 700 }}>{item.value}kW</div>
            </Col>
          ))}
        </Row> */}
        {/* <Row>
          <Tabs
            defaultActiveKey="1"
            tabPosition='top'
          >
            {tabsData?.map((item) => (
              <TabPane tab={
                <div style={{ width: '100px', textAlign: "center" }}>
                  <div>{item.name ?? '--'}</div>
                  <div style={{ fontSize: '12px', marginTop: '2px' }}>{item.value}</div>
                </div>
              } key={item.key}>
              </TabPane>
            ))}
          </Tabs>
        </Row> */}

        <Swiper
          spaceBetween={10}
          slidesPerView={tabsData.length > 2 ? 3 : tabsData.length}
          navigation={
            {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }
          }
          autoplay={{
            delay: 10000,
            stopOnLastSlide: false,
            disableOnInteraction: true,
          }}
          style={{ padding: '0 20px' }}
        // autoplay={{ delay: 1000 }}
        >
          {tabsData?.map((item) => (
            <SwiperSlide key={item.key} >
              <div className={styles.swipe_item}>
                <div title={item.circuitName ?? ''} className={styles.swipe_item_text} style={{ fontSize: '16px', marginTop: '2px' }}>{item.circuitName ?? '--'}</div>
                <div title={item.value + 'kW'} className={styles.swipe_item_text} style={{ fontSize: '12px', marginTop: '2px', color: '#fff' }}>{item.value + 'kW'}</div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className={`swiper-button-prev `} ></div>
        <div className={'swiper-button-next '} ></div>


      </Col>
    </Row>
    <Row>
      <Col span={24}>
        <Table
          // title={() => <div>发电机组</div>}
          size={'small'}
          scroll={{ y: 200 }}
          rowKey={"key"}
          bordered
          dataSource={dataSource}
          columns={columns}
          pagination={false}
        />
      </Col>
    </Row>
  </>
}

export default TabContent;