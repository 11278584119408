import React from "react";
import { Empty } from 'antd';
import { EmptyProps } from 'antd/lib/empty';
import noDataPic from '@/assets/images/noData@2x.png';

interface NoDataProps extends EmptyProps {

}

const NoData: React.FC<NoDataProps> = (props) => {
  return (
    <Empty
      image={noDataPic}
      {...props}
    />
  )
};

export default NoData;
