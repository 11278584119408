import { PermissionsType } from "@/common/permissionsConst";
import { Navigate } from "react-router-dom";
import ElectricityView from ".";


const routes = [
  {
    path: "/",
    element: <Navigate to="/dmes/electricity/view" />
  },
  {
    path: "/dmes/electricity/view",
    element: <ElectricityView/>,
  },
];

export default routes;
