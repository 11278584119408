import { Spin } from "antd";
import { useState } from "react";

const BlankView:React.FC = () => {
  const [loading, setLoading] = useState(false);
  return <>
   <Spin spinning={loading}>
    <div></div>
   </Spin>
  </>
}

export default BlankView;