import { getSupplyView, PropertyIdsType, TimeType } from "@/api/electric";
import { useUpdate } from "@maxtropy/components";
import { Col, Progress, Row, Tooltip } from "antd"
import dayjs from "dayjs";
import { useContext, useEffect, useRef, useState } from "react";
import { Config } from "../..";

type Timer = NodeJS.Timeout | undefined
const TabContent: React.FC = () => {
  const [currentMonthData, setCurrentMonthData] = useState<any[]>([]);
  const [maxData, setMaxData] = useState<number>(0);

  const configValue = useContext(Config);
  const timer = useRef<Timer>();
  const [updateState, updateFn] = useUpdate();

  useEffect(() => {

    if (JSON.stringify(configValue) !== '{}' && configValue) {
      getSupplyView({
        deviceIds: (configValue.pvStation ?? []).map(item => item.deviceId!),
        propertyIds: [PropertyIdsType.ELECT],
        resolution: TimeType.MONTH,
        startTime: dayjs().startOf('month').valueOf(),
        endTime: dayjs().endOf('month').valueOf()
      }).then((res: any) => {
        let newData = JSON.parse(JSON.stringify(configValue.pvStation));
        newData.map((item: { circuitName: string; deviceId: number | undefined; value: any; values: any; }) => {
          item.circuitName = item.circuitName ?? '--';
          let findValue = res.find((i: { deviceId: number | undefined; }) => i.deviceId === item.deviceId)
          if (findValue) {
            item.value = findValue.values ? Number(findValue.values[0].value).toFixed(2) : 0
          } else {
            item.value = 0
          }
        })
        setCurrentMonthData(newData);
        let maxDataSort = newData.map((item: { value: number; }) => Number(Number(item.value).toFixed(2)))
        setMaxData(Math.max(...maxDataSort))
        timer.current = setTimeout(() => {
          updateFn()
        }, (90000))
      }).catch(err => {
        clearTimeout(timer.current);
      })
    }

    return () => clearTimeout(timer.current);
  }, [configValue, updateState]);

  return <>
    <h3>光伏本月发电量</h3>
    <div style={{ height: '230px', overflow: 'auto', paddingRight: '20px' }}>
      {
        currentMonthData.map((item, index) => (
          <Row key={'index' + index} style={{ marginTop: '10px' }} gutter={24}>
            <Col flex={'120px'} style={{ textAlign: 'right', wordBreak: 'break-all' }}>{item.circuitName}</Col>
            <Col flex={'auto'}>
              <Tooltip title={`${item.circuitName}: ${item.value ?? '--'}kWh`}>
                <Progress strokeColor={{
                  '0%': '#36D3FF',
                  '100%': '#19A8FF',
                }} percent={((item.value ? Number(Number(item.value).toFixed(2)) : 0) / maxData) * 100} showInfo={false} />
              </Tooltip>
            </Col>
          </Row>
        ))
      }
    </div>
  </>
}

export default TabContent;