import { Col, Row } from "antd";
import ReactEcharts from "echarts-for-react";
import styles from "./index.module.scss";
import { useContext, useEffect, useState } from "react";
import { Config } from "../..";
import { getRawRecentList, getSupplyView, PropertyIdsType, TimeType } from "@/api/electric";
import dayjs from "dayjs";
import { formateCurrentMonKWValues } from "../../utils";

const TabContent: React.FC = () => {
  const configValue = useContext(Config);
  const [currentMonMaxValue, setCurrentMonMaxValue] = useState<{ value: number; time?: number }>();
  const [pieData, setPieData] = useState<any[]>([]);

  useEffect(() => {
    if (JSON.stringify(configValue) !== "{}" && configValue) {
      getRawRecentList({
        deviceIds: [configValue!.powerGridDeviceId!],
        propertyIds: [PropertyIdsType.NEADS],
      }).then((res) => {
        setCurrentMonMaxValue(res.length !== 0 ? res[0] : undefined);
      });
    }
  }, [configValue]);

  useEffect(() => {
    if (JSON.stringify(configValue) !== "{}" && configValue) {
      getSupplyView({
        deviceIds: [configValue!.powerGridDeviceId!],
        propertyIds: [PropertyIdsType.JIAN, PropertyIdsType.FENG, PropertyIdsType.PING, PropertyIdsType.GU],
        resolution: TimeType.MONTH,
        startTime: dayjs().startOf("month").valueOf(),
        endTime: dayjs().endOf("month").valueOf(),
      }).then((res) => {
        let newData = JSON.parse(JSON.stringify(res));
        newData?.map((item: any) => {
          if (item.propertyId === PropertyIdsType.JIAN) {
            item.name = "尖电量";
            item.value = item.values[0].value.toFixed(2);
          } else if (item.propertyId === PropertyIdsType.FENG) {
            item.name = "峰电量";
            item.value = item.values[0].value.toFixed(2);
          } else if (item.propertyId === PropertyIdsType.PING) {
            item.name = "平电量";
            item.value = item.values[0].value.toFixed(2);
          } else if (item.propertyId === PropertyIdsType.GU) {
            item.name = "谷电量";
            item.value = item.values[0].value.toFixed(2);
          } else {
            item.name = "--";
            item.value = "--";
          }
        });
        setPieData(newData);
      });
    }
  }, [configValue]);

  const getFormatter = (name: string) => {
    if (pieData.length !== 0) {
      let arr = [];
      let total: number = 0;
      let value, percent;
      (pieData ?? []).forEach((item) => {
        total += Number(item.values[0].value);
      });
      (pieData ?? []).map((item) => {
        if (item?.name === name) {
          value = item.values[0].value.toFixed(2) + "kWh";
          percent = ((item.values[0].value / Number(total)) * 100).toFixed(2) + "%";
        }
      });
      arr = ["{a|" + name + "}", "{b|" + value + "}", "{c|" + percent + "}"];
      return arr.join("");
    }
  };

  const getPieOption = () => {
    return {
      tooltip: {
        trigger: "item",
      },
      legend: {
        data: ["尖电量", "峰电量", "平电量", "谷电量"],
        formatter: (name: string) => {
          return getFormatter(name);
        },
        icon: "circle",
        top: "25%",
        right: "5%",
        textStyle: {
          rich: {
            a: {
              fontSize: 14,
              width: 60,
              color: "white",
            },
            b: {
              fontSize: 14,
              width: 110,
              color: "skyblue",
            },
            c: {
              fontSize: 14,
              width: 60,
              color: "yellow",
            },
          },
        },
        orient: "vertical",
      },
      series: [
        {
          type: "pie",
          radius: ["50%", "70%"],
          center: ["20%", "40%"],
          // avoidLabelOverlap: false, // 是否启用防止标签重叠策略，默认开启，在标签拥挤重叠的情况下会挪动各个标签的位置，防止标签间的重叠。
          labelLine: {
            // 标签的视觉引导线配置。在 label 位置 设置为'outside'的时候会显示视觉引导线。
            show: false,
          },
          label: {
            show: false,
            position: "center",
          },
          data: pieData,
          emphasis: {
            itemStyle: {
              color: "#fff",
            },
          },
        },
      ],
    };
  };

  return (
    <>
      <Row gutter={16}>
        <Col span={10}>
          <div style={{ color: "#14D0E4", fontSize: "18px", fontWeight: 700 }}>
            {currentMonMaxValue?.value ? formateCurrentMonKWValues(Number(currentMonMaxValue?.value)) : "--"}
          </div>
          <div style={{ color: "#14D0E4", fontSize: "18px", fontWeight: 700 }}>kW</div>
          <div className={styles.line_through}>
            <span className={styles.line_head}></span>
          </div>
          <span className={styles.elec}>本月最大需量</span>
        </Col>
        <Col span={14}>
          <div style={{ color: "#14D0E4", fontSize: "18px", marginRight: "10px", fontWeight: 700 }}>
            {dayjs(currentMonMaxValue?.time).format("YYYY-MM-DD")}
          </div>
          <div style={{ color: "#14D0E4", fontSize: "18px", marginRight: "10px", fontWeight: 700 }}>
            {dayjs(currentMonMaxValue?.time).format("HH:mm:ss")}
          </div>

          <div className={styles.line_through}>
            <span className={styles.line_head}></span>
          </div>
          <span className={styles.elec}>发生时间</span>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <ReactEcharts option={getPieOption()} style={{ height: "200px" }} notMerge lazyUpdate={false} />
        </Col>
      </Row>
    </>
  );
};

export default TabContent;
