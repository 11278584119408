// import "antd/dist/antd.less";
import "@maxtropy/antd-theme/antd-dark.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
// import { menuData } from "@/common/menu";
import { ThirdLayout as LayoutComponents } from "@maxtropy/components";
import "./App.scss";
import "@/common/config.js";
import Layout from "./Layout";

import RouterView from "@/routes";
import { useEffect, useMemo } from "react";
import qs from "qs";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

function App() {
  return (
    <QueryClientProvider contextSharing client={queryClient}>
      <div className="App">
        <BrowserRouter>
          <Layout>
            <LayoutComponents showStaff={true}>
              <RouterView />
            </LayoutComponents>
          </Layout>
        </BrowserRouter>
      </div>
    </QueryClientProvider>
  );
}

export default App;
