import { Progress } from "antd"
import { useCallback, useMemo } from "react";
import styles from "../../index.module.scss";
import net from "./imgs/icon_net.png";
import fire from "./imgs/icon_fire.png";
import pv from "./imgs/icon_pv.png";
import { formateValues } from "../../utils";

interface Iprops {
  value?: number;
  total?: number;
  type: ProgressCate;

}
export enum  ProgressCate {
  NET,
  FIRE,
  PV
}
const formatType = {
  [ProgressCate.NET]: '电网月供电',
  [ProgressCate.FIRE]: '热电厂月供电',
  [ProgressCate.PV]: '光伏月发电'
}
const mapImg = {
  [ProgressCate.NET]: net,
  [ProgressCate.FIRE]: fire,
  [ProgressCate.PV]: pv
}
const MyProgress: React.FC<Iprops> = ({value = 0, type, total = 0}) => {
  const percent = useMemo(() => {
    let pers = value / total;
    return isNaN(pers) ? 0 : (pers * 100).toFixed(2);
  }, [value, total])
  const strokeColor = useCallback(() => {
    switch(type) {
      case ProgressCate.NET:
        return '#2CC0FF';
      case ProgressCate.FIRE:
        return '#F1B326'
      case ProgressCate.PV:
        return '#FF4D4F'
      default:
        return '#2CC0FF'
    }
  }, [type])
  return (

    <div className={styles.myProgress}>
      <Progress trailColor="#404040" status="normal" type="circle" percent={+percent} strokeColor={strokeColor()}/>
      <div className={styles.info_des}>
        <div className={styles.img_des}>
          <img src={mapImg[type]} alt="" />
        </div>
        <div className={styles.top}>
          <span className={styles.today_value}>{formateValues(value)}</span>
          <div className={styles.line_through}><span className={styles.line_head}></span></div>
          <span className={styles.elec}>{formatType[type]}</span>
        </div>
      </div>
    </div>

  )
}

export default MyProgress