import { Space, Spin } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import ReactEcharts from "echarts-for-react";
import { ColorTypeProps, getContractRateElectricityPriceList, getLinePower, NameTypeProps, TimeAndNameTypeProps } from "@/api/electric";
import { Config } from "../..";
import dayjs from "dayjs";
import { useUpdate } from "@maxtropy/components";
import styles from './index.module.scss';

interface Iprops {
  currentKey: string
}
type Timer = NodeJS.Timeout | undefined
const LineBg: React.FC<Iprops> = (props) => {
  const { currentKey } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isDataEmpty, setIsDataEmpty] = useState(false);
  const [rateElectricityPrice, setRateElectricityPrice] = useState<any[]>([]);
  const [lineInfoData, setLineInfoData] = useState<any[]>([]);
  const timer = useRef<Timer>();

  const configValue = useContext(Config);
  const [updateState, updateFn] = useUpdate();

  useEffect(() => {
    if (JSON.stringify(configValue) !== '{}' && configValue && currentKey) {
      Promise.all([getContractRateElectricityPriceList(), getLinePower({ type: Number(currentKey) })]).then(([contractData, lineInfo]) => {
        setRateElectricityPrice(contractData);
        setLineInfoData(lineInfo);
        timer.current = setTimeout(() => {
          updateFn()
        }, (90000))
      }).catch(err => {
        clearTimeout(timer.current)
      })
    }
    return () => clearTimeout(timer.current);
  }, [configValue, currentKey, updateState]);

  const getLineOption = () => {
    return {
      title: {
        text: '功率曲线',
        textStyle: {
          color: '#fff'
        },
      },
      // legend: [
      //   {
      //     textStyle: {
      //       fontSize: 12
      //     },
      //     data: ['流量（MB）']
      //   }
      // ],
      grid: {
        top: 55
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        }
      },
      toolbox: {
        show: true,
      },
      xAxis: {
        type: 'time',
        boundaryGap: false,
        axisLabel: {
          formatter: {
            day: '{HH}:{mm}',
            hour: '{HH}:{mm}',
          }
        }
      },
      dataZoom: [
        {
          type: 'slider',
          start: 0,
          end: 100,
          bottom: 15,
          height: 20,
          backgroundColor: 'rgba(226,227,229,0.5)',
          fillerColor: 'rgba(142,177,224,0.3)',
          textStyle: {
            fontSize: 10
          }
        },
        { type: 'inside' }
      ],
      yAxis: {
        name: 'kW',
        type: 'value',
        axisLabel: {
          formatter: '{value}'
        },
        axisPointer: {
          snap: true
        }
      },
      series: [
        {
          name: '有功功率',
          type: 'line',
          smooth: true,
          // prettier-ignore
          data: lineInfoData.length !== 0 ? lineInfoData.map((item: { value: number; time: number }) => [item.time, item.value ? item.value.toFixed(2) : '--']) : [],
          markArea: {
            data:
              (rateElectricityPrice ?? []).map(item => {
                return [
                  {
                    // name: NameTypeProps[(item.type) as TimeAndNameTypeProps],
                    label: {
                      color: 'white'
                    },
                    xAxis: dayjs(dayjs().format('YYYY-MM-DD ') + item.startTime).valueOf(),
                    itemStyle: {
                      color: ColorTypeProps[(item.type) as TimeAndNameTypeProps]
                    },
                  },
                  {
                    xAxis: dayjs(dayjs().format('YYYY-MM-DD ') + item.endTime).valueOf(),
                  }
                ]
              })
          }
        }
      ]
    }
  }

  return (
    <Spin spinning={isLoading}>
      <Space className={styles.block}>
        {
          Object.entries(NameTypeProps).map(([value, key]) => {
            const color = `${ColorTypeProps[+value as TimeAndNameTypeProps]}`;
            return (
              <span
                key={key}
                className={styles.item}
              >
                <span
                  style={{
                    background: color,
                  }}
                  className={styles.pipe}
                />
                {key}
              </span>
            )
          })
        }
      </Space>
      <ReactEcharts
        option={getLineOption()}
        style={{ height: "300px" }}
        notMerge
        lazyUpdate={false}
      />
    </Spin>
  )
}


export default LineBg;