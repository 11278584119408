import styles from './index.module.scss';
const HeadTitle: React.FC<{title?: string}> = ({title}) => {

  return (
    <div className={styles.lineTitle}>
      <div className={styles.titleBorder}></div>
      {title ?? ''}
    </div>
  );
};

export default HeadTitle;