import moment from "moment";

export const needAddZero = (value: number): string => {
  let tempValue = (value).toFixed(2);
  let tempNum = +tempValue.split('.')[1];
  if (!tempNum) {
    return '.00'
  }
  if ((tempNum).toString().length === 1) {
    return '0'
  }
  if (Number((tempNum).toString()) % 10 === 0) {
    return '0'
  }
  return ''
}
// 格式化电量
export const formateValues = (value: number) => {
  if (value >= 100000) {
    let zero = needAddZero(value / 10000);
    return Number((value / 10000).toFixed(2)).toLocaleString() + zero + ' 万kWh';
  } else {
    let zero = needAddZero(value);
    return Number(value.toFixed(2)).toLocaleString() + zero + ' kWh';
  }
}

// 本月最大需量kW
export const formateCurrentMonKWValues = (value: number, unit = '') => {
  let zero = needAddZero(value);
  return Number(value.toFixed(2)).toLocaleString() + zero + ' ' + unit;
}
// 格式化时间
export const splitTime = (split: number) => {
  const startTime = moment().startOf('day').valueOf();
  const endTime = moment().endOf('day').valueOf();
  let currentTime = startTime;
  let time: number[] = [];
  while (currentTime < endTime) {
    time.push(currentTime);
    currentTime += split * 60 * 1000;
  }
  return time;
}