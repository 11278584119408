import { Col, Row, Tabs } from "antd";
import { useMemo, useState } from "react";
import LineBg from "./LineBg";
import NetTabContent from "./NetTabContent";
import FireTabContent from "./FireTabContent";
import PvTabContent from "./PvTabContent";
import { ConfigResponse } from "@/api/electric";
const { TabPane } = Tabs;

interface Iprops {
  config?: ConfigResponse;
}

const tabs = [
  {
    key: "1",
    name: "电网",
    component: <NetTabContent />,
  },
  {
    key: "2",
    name: "热电厂",
    component: <FireTabContent />,
  },
  {
    key: "3",
    name: "光伏",
    component: <PvTabContent />,
  },
];

const Elec: React.FC<Iprops> = () => {
  const [currentKey, setCurrentKey] = useState("1");

  const tabRenderDom = useMemo(() => {
    let tempInfo = tabs.find((item) => item.key === currentKey);
    return tempInfo?.component;
  }, [currentKey]);
  const tabClick = (key: string) => {
    setCurrentKey(key);
  };
  return (
    <>
      <Row justify="end">
        <Tabs size="small" type="card" onTabClick={tabClick}>
          {tabs?.map((item) => (
            <TabPane
              tab={<div style={{ width: 80, textAlign: "center" }}>{item.name ?? "--"}</div>}
              key={item.key}
            ></TabPane>
          ))}
        </Tabs>
      </Row>
      <Row>
        <Col lg={12} md={24} sm={24}>
          <LineBg currentKey={currentKey} />
        </Col>
        <Col lg={12} md={24} sm={24}>
          {tabRenderDom}
        </Col>
      </Row>
    </>
  );
};

export default Elec;
