import BlankView from ".";

const routes = [
  {
    path: "/dmes/electricity/landing",
    element: <BlankView/>,
  },
];

export default routes;
