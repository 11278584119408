import { fetch } from "@maxtropy/components";
import { omit } from "lodash-es";
import qs from "qs";
import { Key } from "react";

// 获取设备id
export interface CircuitProps {
  circuitId?: number;
  circuitName?: string;
  deviceId?: number;
}
export interface ConfigResponse {
  loadRanking?: CircuitProps[];
  powerGridDeviceId?: number;
  pvStation?: CircuitProps[];
  thermalPowerPlant?: CircuitProps[];

}
export const getDeviceIdConfig = () => {
  return fetch<ConfigResponse>('/api/powerSummary/config');
}
export enum TimeType {
  HOUR = "H",
  DAY = "D",
  MONTH = "M",
}
export enum PropertyIdsType {
  POWER = 10017, // 总有功功率
  ELECT = 10022, // 总有功电能
  NEADS = 10121, // 有功需量
  JIAN = 10122, // 尖正向有功
  FENG = 10123, // 峰正向有功
  PING = 10124, // 平正向有功
  GU = 10125, // 谷正向有功
}


// 尖峰平谷
export enum TimeAndNameTypeProps {
  SHARPPEAK = 1, // 尖峰
  PEAK = 2, // 峰
  AVERAGE = 3, // 平
  VALLEY = 4 // 谷
}

export const NameTypeProps = {
  [TimeAndNameTypeProps.SHARPPEAK]: '尖时段', // 尖峰
  [TimeAndNameTypeProps.PEAK]: '峰时段', // 峰
  [TimeAndNameTypeProps.AVERAGE]: '平时段', // 平
  [TimeAndNameTypeProps.VALLEY]: '谷时段' // 谷
}

export const ColorTypeProps = {
  [TimeAndNameTypeProps.SHARPPEAK]: 'rgba(255,119,0,0.2)', // 尖峰
  [TimeAndNameTypeProps.PEAK]: 'rgba(245,125,255,0.2)', // 峰
  [TimeAndNameTypeProps.AVERAGE]: 'rgba(27,151,255,0.2)', // 平
  [TimeAndNameTypeProps.VALLEY]: 'rgba(52,211,204,0.23)' // 谷
}
export interface QueryProps {
  deviceIds: number[];
  propertyIds: PropertyIdsType[];
  resolution?: TimeType;
  startTime: number;
  endTime: number;
}

// 供电总览聚合累计值
export interface ChartValueProps {
  time?: number;
  value?: number;
}
export interface ChartsResponse {
  deviceId?: number;
  propertyId?: number;
  values?: ChartValueProps[]
}
export const getSupplyView = (query: QueryProps) => {
  return fetch<ChartsResponse[]>(`/api/time-aggr/cumulative?${qs.stringify(query, { indices: false })}`)
}

// 负荷排名查询条件
export interface RecentQueryProps {
  deviceIds: number[];
  propertyIds: PropertyIdsType[];
}

// 负荷排名返回数据类型
export interface RecentResponseProps {
  deviceId: number;
  propertyId: number;
  time: number;
  value: number;
}
// 最近原始数据
export const getRawRecentList = (query: RecentQueryProps) => {
  return fetch<RecentResponseProps[]>(`/api/raw/recent?${qs.stringify(query, { indices: false })}`);
}

// 获取合同尖峰平谷信息
export const getContractRateElectricityPriceList = () => {
  return fetch<ContractRateElectricityPriceProps[]>(`/api/contract/rateElectricityPrice`);
}

// 获取重采样瞬时值
export const getResampledInstanceList = (query: QueryProps) => {
  return fetch<any[]>(`/api/resampled/instantaneous?${qs.stringify(query, { indices: false })}`);
}

// 获取曲线数据
export const getLinePower = (query: { type: number }) => {
  return fetch<any[]>(`/api/line/power?${qs.stringify(query, { indices: false })}`);
}

// 获取合同尖峰平谷信息返回数据类型
export interface ContractRateElectricityPriceProps {
  id: number;
  type: number;
  startTime: string;
  endTime: string;
  deviceId: number;
  price: number;
  electricityContractId: number;
  startDate: string;
  endDate: string;
  rateElectricityPriceId: string;
  displayType: number;
}



